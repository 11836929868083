import { BaseHeader } from '~/components/base/menu/baseHeader'

export default function IndexPage() {
  return (
    <div className="relative w-full flex flex-col">
      <BaseHeader />

      <div className="mt-20 py-2">
        <div className="flex flex-col items-center space-y-2">
          <div>
            <h1 className="text-lg font-semibold mb-4">Welcome to Kalir 📦</h1>
            <p>
              If you are a customer, please find the link of your dedicated page
              from our emails.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
